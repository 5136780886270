.menu{
  position: relative;
  top:16px;

  display: flex;

  width: calc( 100% - 500px);
  margin-right: 110px;
}

.menuItem{
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.menu span:hover{
  cursor: pointer;
}

.iconBurger{
  margin-top: 24px;
  margin-left: 20px;
  font-size: 30px;
  color: #00ADEF;
}
.mobileMenu{
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;

  overflow: hidden;
  display: block;

  width: 100%;
  height: 80px;
  min-height: 80px;

  background: #FFFFFF;
}
.mobileMenu.active{
  min-height: 100vh;
}

.logo{
  position: absolute;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 80px;
  margin-right: auto;
  margin-left: auto;
  padding: 0;

  text-align: center;
}

.header{
  width: 100%;
  min-height: 80px;
}

.exitIcon path{
  fill: #00ADEF;
}

.avatar{
  position: absolute;
  top: 24px;
  right: 30px;

  width: 36px;
  height: 36px;

  font-size: 20px;

  border-radius: 50%;
}

.search{
  position: absolute;
  top: 30px;
  right: 90px;
  transform: scale(-1, 1);

  font-size: 26px;
  color: #2C373D;
}