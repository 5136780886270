.headerAnt {
  position: fixed;
  z-index: 50;
  top: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;

  width: 100%;
  max-width: 100vw;
  height: 55px;

  background-color: var(--primary-background);
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding-bottom: 5px;
}

.leftBlock {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 45%;
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 5%;
  min-width: 35px;

  text-align: center;
}

.logoText {
  margin: 0 7px 0 10px;

  font-size: 24px;
  font-weight: 600;
  color: #fff;
  text-align: center;

  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.isMainPage {
  color: var(--primary-color);
}

.isMainPage:hover {
  color: var(--primary-color-hover);
}

.logoTextSmall {
  margin-right: 10px;
  margin-left: 17px;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.65);
  text-align: center;

  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.logoTextSmall:hover {
  color: #fff;
}
