html {
  --red: #f52f41;
  --primary-color: #e8a236;
  --primary-color-hover: #ecb563;
  --primary-background: #1e1d1d;
  --white: #fff;
  --input-disabled: #e3e3e3;

  --rate-5: #906b12;
  --rate-4: #ac7f0f;
  --rate-3: #c8930d;
  --rate-2: #e8a236;
  --rate-1: #ecb563;

  overflow-x: hidden;
  overflow-y: auto;
}

body {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  background-color: var(--primary-background);
}

.loadingPage {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;
}

.textWhite {
  color: white;
}

.myH1 {
  width: 100%;
  font-size: 20px;
  color: var(--primary-background);
  text-align: center;
}

.contentBody {
  /* display: flex;
  justify-content: center;
  align-items: flex-start; */
  flex-wrap: wrap;
  min-width: 300px;
  background: var(--primary-background);
}

.game [class^="ant-typography"],
.game .ant-checkbox-wrapper {
  font-size: 12px;
}

.game .ant-drawer-header {
  padding: 12px 24px !important;
  border: 1px solid transparent;
}

.game .ant-drawer-content {
  width: 100%;
  background-color: transparent;
  border-radius: 5px;
}

.game .ant-drawer-content-wrapper {
  width: 100% !important;
}

.game .ant-drawer-wrapper-body,
.game .ant-drawer-close {
  color: var(--white);
  background-color: var(--primary-background);
}

.game .ant-drawer-body {
  overflow-x: visible;
  overflow-y: auto;
  padding: 0 24px 12px 24px;
}

.game .ant-drawer-body::-webkit-scrollbar,
.game .ant-popover-inner-content::-webkit-scrollbar {
  width: 3px;
}

.game .ant-drawer-body::-webkit-scrollbar-thumb,
.game .ant-popover-inner-content::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 20px;
}

.game .ant-drawer-body::-webkit-scrollbar-track,
.game .ant-popover-inner-content::-webkit-scrollbar-track {
  background: var(--primary-background);
}

.game .ant-drawer-close:hover {
  color: var(--primary-color-hover);
}

.ant-tabs-dropdown > ul {
  background-color: var(--primary-background) !important;
}

.ant-tabs-dropdown > ul > li {
  text-align: center;
}

.inventory .ant-tabs-tab svg,
.ant-tabs-dropdown > ul > li svg {
  transition: all 0.3s;
}

.ant-tabs-dropdown > ul > li:hover svg {
  fill: var(--primary-color-hover);
}

.inventory .ant-tabs-tab-active svg {
  fill: var(--primary-color);
}

.inventory .ant-tabs-tab:hover svg {
  fill: var(--primary-color-hover);
}

.whiteBackground {
  min-height: 100vh;
  padding: 55px 40px 0 40px;
  background-color: #fff;
}

.pad-0 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 !important;
}

body:has(.whiteBackground) {
  background-color: #fff;
}

.layout {
  margin: 0;
  padding: 0;
}

.ant-layout {
  background-color: #fff;
}

.ant-input:hover {
  border-color: var(--primary-color) !important;
}

.ant-checkbox-inner {
  border-color: var(--primary-color) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color) !important;
}

.ant-btn-primary {
  background-color: var(--primary-color);
}

.ant-btn-primary:hover {
  background-color: var(--primary-color-hover) !important;
}

.ant-card-body {
  padding: 10px 5px !important;
  text-align: center;
}

.ant-table-wrapper .ant-table {
  background-color: transparent !important;
}

.guild-table {
  margin: 20px auto 10px auto;
}

.guild-table::-webkit-scrollbar {
  width: 3px;
}

.guild-table::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 20px;
}

.guild-table::-webkit-scrollbar-track {
  background: var(--primary-background);
}

.guild-table .ant-table-cell-scrollbar {
  width: 0 !important;
  padding: 0 !important;
}

.guild-table .ant-table-body::-webkit-scrollbar {
  width: 3px !important;
  height: 5px !important;
}
.guild-table .ant-table-body::-webkit-scrollbar-thumb {
  background: var(--primary-color) !important;
  border-radius: 20px !important;
}
.guild-table .ant-table-body::-webkit-scrollbar-track {
  background: var(--primary-background) !important;
}

.guild-table .ant-table-column-sorter {
  color: #fff !important;
}

.guild-table .anticon-search {
  color: #fff !important;
}

.guild-table .anticon-filter {
  color: #fff !important;
}

.guild-table .ant-table-thead td:last-of-type {
  /* display: none; */
}

.guild-table .ant-table-thead td:first-of-type::before {
  /* display: none; */
}

.guild-table .ant-table-expanded-row-fixed,
.guild-table .ant-empty-description,
.guild-table .ant-table-placeholder,
.guild-table .ant-table-placeholder:hover > td {
  color: #fff !important;
  background: var(--primary-background) !important;
}

.ant-table-thead {
  overflow: hidden;
}

.ant-tabs-tab-btn {
  font-size: 12px;
  color: #fff;
}

.ant-pagination-item-link {
  color: var(--primary-color) !important;
}

.guild-column-header {
  color: #fff !important;
  background-color: var(--primary-color) !important;
}

.guild-column {
  padding: 3px 8px !important;
  font-size: 10px !important;
  color: #fff !important;
  background-color: transparent !important;
}

.guild-column:hover ~ .ant-table-cell-row-hover {
  background-color: transparent !important;
}

.guild-column span {
  font-size: 10px !important;
}

.main-content-page {
  padding: 0;
}

.title-page-not-found {
  justify-content: center;

  margin-top: 20px;
  padding-top: 20px;

  font-size: 16px;
  font-weight: 500;
}

.pagination {
  margin-top: 20px;
}

.pagination.ant-pagination .ant-pagination-item:hover,
.pagination.ant-pagination .ant-pagination-item:active,
.pagination.ant-pagination .ant-pagination-item {
  width: 48px;
  height: 48px;

  font-weight: 600;

  background-color: #ffffff;
  border-color: #dde0eb;
  border-radius: 2px;
}

.pagination.ant-pagination .ant-pagination-item a:hover,
.pagination.ant-pagination .ant-pagination-item a:active,
.pagination.ant-pagination .ant-pagination-item a {
  font-size: 13px;
  font-weight: 500;
  line-height: 48px;
  color: #001333;
  text-align: center;
  letter-spacing: 0.07em;
}

.contentBody .ant-pagination-item a {
  color: #fff;
}

.whiteBackground .ant-pagination-item a {
  color: var(--primary-background);
}

.contentBody .ant-pagination-item-active a {
  color: var(--primary-color);
}

.pagination.ant-pagination .ant-pagination-item-active {
  width: 48px;
  height: 48px;

  font-weight: 600;

  background-color: var(--primary-color-hover);
  border-color: var(--primary-color-hover);
  border-radius: 2px;
}

.pagination.ant-pagination .ant-pagination-item-active a {
  font-size: 13px;
  font-weight: 500;
  line-height: 48px;
  color: #ffffff;
  text-align: center;
  letter-spacing: 0.07em;
}

.pagination.ant-pagination .ant-pagination-prev,
.pagination.ant-pagination .ant-pagination-jump-next,
.pagination.ant-pagination .ant-pagination-jump-prev,
.pagination.ant-pagination .ant-pagination-next {
  width: 48px;
  height: 48px;

  font-weight: 600;

  background-color: #ffffff;
  border: solid 1px #dde0eb;
  border-radius: 2px;
}

.ant-pagination-jump-next,
.ant-pagination-jump-next span {
  color: var(--primary-color) !important;
  background-color: transparent;
}

.ant-pagination-jump-next:hover span {
  color: var(--primary-color-hover);
}

.pagination.ant-pagination .ant-pagination-item-link {
  line-height: 48px;
}

.pagination.ant-pagination
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.pagination.ant-pagination
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: black;
}

.pagination
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-search,
.pagination
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  display: flex;
  align-items: center;
}

.pagination
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 46px;
}

.ant-picker-date-panel {
  width: 240px !important;
}

.ant-picker-content {
  width: auto !important;
}

.tabs-row {
  gap: 20px;
  margin: 10px 0;
}

.tabs-col {
  cursor: pointer;

  position: relative;

  display: inline-flex;
  align-items: center;

  padding: 12px 0;

  font-size: 14px;

  background: transparent;
  border: 0;
  border-bottom: solid 2px #ffffff;
  outline: none;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.tabs-col-active {
  color: var(--primary-color);
  border-bottom: solid 2px var(--primary-color);
}

.item-required .ant-form-item-label > label::before {
  content: "*";

  display: inline-block;

  margin-inline-end: 4px;

  font-family: SimSun, sans-serif;
  font-size: 14px;
  line-height: 1;
  color: #ff4d4f;
}

.add-btn-container {
  width: 200px;
  padding-top: 30px;
}

.body-container {
  width: calc(100% - 200px);
}

.form-actions-edit {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.modal-title,
.drawer-title {
  width: 100%;
  margin: 0;
  padding-right: 36px;

  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.drawer-title {
  font-size: 14px;
  color: var(--primary-color);
}

.ant-menu-dark * {
  transition: bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-menu-dark > .ant-menu {
  background-color: var(--primary-background) !important;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item-selected {
  color: var(--primary-color);
  background-color: transparent !important;
}

.ant-menu-horizontal .ant-menu-item {
  padding-inline: 13px !important;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item-selected:hover {
  color: var(--primary-color-hover);
}

.breadcrumb {
  margin-top: 10px;
}

.breadcrumb a:hover {
  color: var(--primary-color-hover);
  background-color: transparent;
}

@media screen and (max-width: 750px) {
  .displayNone750 {
    display: none;
  }
  .displayTrue750 {
    display: flow;
  }
  .contentBody {
    margin: 0;
    padding: 3px;
  }

  .whiteBackground {
    padding: 55px 20px 0 20px;
  }
}

@media screen and (min-width: 750px) {
  .displayNone750 {
    display: flow;
    padding: 0 10px;
    /* border-left: 1px solid var(--primary-background); */
  }

  .displayTrue750 {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .main-content {
    min-height: calc(100vh - 350px);
  }
  .main {
    padding: 10px 0 50px;
  }
  .page-banner-head {
    height: 134px;
    margin-top: 6px;
  }

  .main-content-page {
    padding: 0 20px;
  }
}

.events-tree{
  margin: 10px 8px ;
  background: transparent;
}

.events-tree.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected{
  background: transparent;
}

.event-item{
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.event-title{
  padding-left: 2px;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: var(--primary-color);
}

.event-count,
.event-energy{
  display: flex;
  gap: 6px;

  padding-left: 10px;

  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--rate-1);
}

.event-count span,
.event-energy span{
  font-weight: 600;
}
.event-count .svg-container,
.event-energy .svg-container{
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;
}
.event-count .svg-container svg,
.event-energy .svg-container svg{
  width: 16px;

}

.event-count .svg-container svg g,
.event-count .svg-container svg g path,
.event-energy .svg-container svg path{
  fill: var(--rate-1) !important;
  stroke: var(--rate-1);
}

.popover-item-text{
  font-size: 14px;
  color: rgba(0, 0, 0, 0.88);
}

.btn-back{
  border: none;
}

.ant-btn-buy {
  color: var(--primary-color);
  background-color: transparent;
  border-color: var(--primary-color);
}

.ant-btn-buy:hover,
.ant-btn-buy:active,
.ant-btn-buy:focus {
  color: var(--primary-color);
  background-color: transparent;
  border-color: var(--primary-color);
}


.ant-btn-danger {
  color:#FFF;
  background-color: #f5222d;
  border-color:#f5222d;
}

.ant-btn-danger:hover,
.ant-btn-danger:active,
.ant-btn-danger:focus {
  color:#FFF;
  background-color: #ff4d4f;
  border-color:#ff4d4f;
}

.row-count-barter{
  gap: 10px;
  margin: 10px 0 20px;
}

.row-title-barter{
  overflow: hidden;
  justify-content: center;

  max-width: 220px;

  font-weight: 700;
  text-align: center;
  white-space: pre-wrap;
}

.tabs-my-barter{
  position: sticky;
  z-index: 10;
  top: 0;
  background: var(--primary-background);
}