.up {
  cursor: pointer;

  position: fixed;
  z-index: 2;
  right: 21px;
  bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px !important;
  height: 40px;

  background: var(--primary-background);
  border: none;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.up svg {
  height: 37px;
  fill: var(--primary-color);
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.up:hover svg {
  fill: var(--primary-color-hover);
}